import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import ComponentReferenceModel from "src/shared/components/ComponentReferenceModel";

const ComponentReferenceCollection = Backbone.Collection.extend({
    model: ComponentReferenceModel,
    comparator: "absolute_name",
    addReferenceTo(componentModel) {
        const absoluteName = _.first(componentModel.get("absolute_names"));
        this.add({
            absolute_name: absoluteName,
            tags: [],
        });
    },
    matchesComponent(componentModel) {
        let result = false;
        const absoluteNames = componentModel?.get("absolute_names") || [];
        this.forEach((componentReferenceModel) => {
            if (
                _.contains(
                    absoluteNames,
                    componentReferenceModel.get("absolute_name")
                )
            ) {
                result = true;
            }
        });
        return result;
    },
});

ComponentReferenceCollection.create = function (componentReferences) {
    componentReferences = componentReferences || [];
    return new ComponentReferenceCollection(componentReferences);
};

export default ComponentReferenceCollection;
