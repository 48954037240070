import Backbone from "backbone";
import _ from "underscore";
import Strings from "src/shared/Strings";
import ActionDisplayAttributes from "src/shared/ActionDisplayAttributes";
/*
 * An incentivize_action can have multiple controllers fire it, each one of those
 * controllers can also have multiple flow steps assigned to them.
 *
 * When multiple "types" of steps or flow steps are configured to output an action_type.
 * This gets the name of the most frequently used "type" so if one oddball step_name is
 * configured, it most likeley won't mess up the name
 */

function IncentivizedStep(actionType, controllers, flowSteps) {
    this._actionType = actionType;
    this._controllers = controllers;
    this._flowSteps = flowSteps;

    const representativeController =
        getMostUsed(groupByAttribute("name", controllers))[0] || {};
    const representativeFlowStep =
        getMostUsed(groupByAttribute("step_name", flowSteps))[0] || {};

    this.getActionType = function () {
        return this._actionType;
    };
    this.getDisplayName = function () {
        let displayName = representativeFlowStep.name;
        if (displayName) {
            return displayName;
        }
        displayName = representativeController.name;
        if (!displayName) {
            displayName = this.getActionType();
        }
        return Strings.capitalize(displayName);
    };
    this.getSequence = function () {
        return representativeFlowStep.sequence;
    };
    this.getIcon = function () {
        const iconType = representativeFlowStep.icon_type;
        if (iconType) {
            return `far fa-${iconType}`;
        }
    };
}

IncentivizedStep.Legacy = function (displayName, actionType) {
    this.getActionType = function () {
        return actionType;
    };
    this.getDisplayName = function () {
        if (displayName) {
            return displayName;
        }
        if (actionType) {
            return Strings.capitalize(actionType);
        }
        return "--";
    };
    this.getIcon = function () {
        if (actionType) {
            return (
                ActionDisplayAttributes.ICONS_CLASSES[actionType] ||
                ActionDisplayAttributes.ICONS_CLASSES.DEFAULT
            );
        }
    };
};

function groupByAttribute(key, items) {
    const map = {};
    _.each(items, (item) => {
        if (!map[item[key]]) {
            map[item[key]] = [];
        }
        map[item[key]].push(item);
    });
    return map;
}

function getMostUsed(map) {
    let largestLength = 0;
    let largestArray = [];
    _.each(map, (value) => {
        if (value.length > largestLength) {
            largestLength = value.length;
            largestArray = value;
        }
    });
    return largestArray;
}
export default IncentivizedStep;
