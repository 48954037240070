import Backbone from "backbone";
import _ from "underscore";
import omissible from "src/shared/api/omissible";

const COMMON_ATTRIBUTES = [
    "enabled",
    "trigger_phase",
    "trigger_name",
    "component_ids",
    "negated",
];
const COMMON_OMISSIBLE_ATTRIBUTES = [
    "trigger_phase",
    "trigger_name",
    "negated",
    "component_ids",
];
const idAttribute = "trigger_id";
const TRIGGER_TYPE_CONFIGURATIONS = {
    ACCESS: {
        name: "ACCESS",
        display_name: "Access",
        path: "accesses",
        attributes: _.union(["trusted_scopes"], COMMON_ATTRIBUTES),
        createAttributes: [],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    EVENT: {
        name: "EVENT",
        display_name: "Event",
        path: "events",
        attributes: _.union(["event_type", "event_names"], COMMON_ATTRIBUTES),
        createAttributes: [],
        supported_evaluatable_type: "buildtime",
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    ZONE_STATE: {
        name: "ZONE_STATE",
        display_name: "Zone State",
        path: "zone-states",
        attributes: _.union(
            ["zone_name", "step_name", "invert_mapping_state"],
            COMMON_ATTRIBUTES
        ),
        createAttributes: [],
        supported_evaluatable_type: "buildtime",
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    SCORE: {
        name: "SCORE",
        display_name: "Score",
        path: "scores",
        attributes: _.union(
            ["score_result", "cause_event_name"],
            COMMON_ATTRIBUTES
        ),
        createAttributes: ["channel"],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    SHARE: {
        name: "SHARE",
        display_name: "Share",
        path: "shares",
        attributes: _.union(["channels", "quality"], COMMON_ATTRIBUTES),
        createAttributes: [],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    LEGACY_QUALITY: {
        name: "LEGACY_QUALITY",
        display_name: "Legacy Quality",
        path: "legacy-quality",
        attributes: _.union(["action_type"], COMMON_ATTRIBUTES),
        createAttributes: [],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    REFERRED_BY_EVENT: {
        name: "REFERRED_BY_EVENT",
        display_name: "Referred By Event",
        path: "referred-by-events",
        attributes: _.union(["referral_originator"], COMMON_ATTRIBUTES),
        createAttributes: [],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    EXPRESSION: {
        name: "EXPRESSION",
        display_name: "Expression",
        path: "expressions",
        attributes: _.union(["data", "expression"], COMMON_ATTRIBUTES),
        createAttributes: [],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    DATA_INTELLIGENCE_EVENT: {
        name: "DATA_INTELLIGENCE_EVENT",
        display_name: "Data Intelligence Event",
        path: "data-intelligence-events",
        attributes: _.union(["event_name"], COMMON_ATTRIBUTES),
        createAttributes: [],
        supported_evaluatable_type: "buildtime",
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES, "event_name"],
    },
    MAXMIND: {
        path: "max-minds",
        name: "MAXMIND",
        display_name: "MaxMind",
        attributes: _.union(
            [
                "default_quality_score",
                "allow_high_risk_email",
                "ip_threshold",
                "risk_threshold",
            ],
            COMMON_ATTRIBUTES
        ),
        createAttributes: [],
        supported_evaluatable_type: "buildtime",
        omissibleAttributes: [
            ...COMMON_OMISSIBLE_ATTRIBUTES,
            "default_quality_score",
            "allow_high_risk_email",
            "ip_threshold",
            "risk_threshold",
        ],
    },
    HAS_PRIOR_STEP: {
        name: "HAS_PRIOR_STEP",
        display_name: "Has Prior Step",
        path: "has-prior-steps",
        attributes: _.union(
            [
                "filter_names",
                "filter_matching_partner_event_id",
                "filter_scope",
                "filter_quality",
                "filter_partner_event_id",
                "filter_partner_event_id_name",
                "filter_partner_event_id_value",
                "filter_min_age",
                "filter_min_value",
                "filter_max_age",
                "filter_max_value",
                "filter_expression",
                "filter_expressions",
                "filter_campaign_id",
                "filter_campaign_ids",
                "count_max",
                "count_min",
                "filter_names",
                "filter_min_date",
                "filter_max_date",
                "filter_program_label",
                "filter_program_labels",
                "count_matches",
                "filter_min_value",
                "filter_max_value",
                "sum_of_value_min",
                "sum_of_value_max",
                "person_id",
            ],
            COMMON_ATTRIBUTES
        ),
        createAttributes: [],
        defaults: {
            filter_min_date: null,
            filter_max_date: null,
        },
        omissibleAttributes: [
            ...COMMON_OMISSIBLE_ATTRIBUTES,
            "trigger_name",
            "filter_names",
            "filter_matching_partner_event_id",
            "filter_scope",
            "filter_quality",
            "filter_partner_event_id",
            "filter_partner_event_id_name",
            "filter_partner_event_id_value",
            "filter_min_age",
            "filter_min_value",
            "filter_max_age",
            "filter_max_value",
            "filter_expression",
            "filter_expressions",
            "filter_campaign_id",
            "filter_campaign_ids",
            "count_max",
            "count_min",
            "filter_names",
            "filter_min_date",
            "filter_max_date",
            "filter_program_label",
            "filter_program_labels",
            "count_matches",
            "filter_min_value",
            "filter_max_value",
            "sum_of_value_min",
            "sum_of_value_max",
            "person_id",
        ],
    },
    REWARD_EVENT: {
        name: "REWARD_EVENT",
        display_name: "Reward Event",
        path: "reward-events",
        attributes: _.union(
            ["reward_states", "event_names", "slots"],
            COMMON_ATTRIBUTES
        ),
        createAttributes: [],
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    HAS_PRIOR_REWARD: {
        name: "HAS_PRIOR_REWARD",
        display_name: "Has Prior Reward",
        path: "has-prior-rewards",
        attributes: _.union(
            [
                "filter_scope",
                "filter_tags",
                "filter_min_age",
                "filter_max_age",
                "count_matches",
                "filter_reward_supplier_ids",
                "filter_face_value_types",
                "filter_states",
                "filter_expression",
                "filter_expressions",
                "sum_of_face_value_max",
                "sum_of_face_value_min",
                "count_max",
                "count_min",
                "filter_names",
                "filter_min_date",
                "filter_max_date",
            ],
            COMMON_ATTRIBUTES
        ),
        createAttributes: [],
        defaults: {
            filter_min_date: null,
            filter_max_date: null,
        },
        omissibleAttributes: [
            ...COMMON_OMISSIBLE_ATTRIBUTES,
            "trigger_name",
            "filter_scope",
            "filter_tags",
            "filter_min_age",
            "filter_max_age",
            "count_matches",
            "filter_reward_supplier_ids",
            "filter_face_value_types",
            "filter_states",
            "filter_expression",
            "filter_expressions",
            "sum_of_face_value_max",
            "sum_of_face_value_min",
            "count_max",
            "count_min",
            "filter_names",
            "filter_min_date",
            "filter_max_date",
        ],
    },
    SEND_REWARD_EVENT: {
        name: "SEND_REWARD_EVENT",
        display_name: "Send Reward Event",
        path: "send-reward-events",
        attributes: _.union(
            ["reward_states", "reward_names", "tags"],
            COMMON_ATTRIBUTES
        ),
        createAttributes: [],
        supported_evaluatable_type: "buildtime",
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    AUDIENCE_MEMBERSHIP_EVENT: {
        name: "AUDIENCE_MEMBERSHIP_EVENT",
        display_name: "Audience Membership Event",
        path: "audience-membership-events",
        attributes: _.union(["audience_ids", "event_types"], COMMON_ATTRIBUTES),
        omissibleAttributes: [
            ...COMMON_OMISSIBLE_ATTRIBUTES,
            "audience_ids",
            "event_types",
        ],
    },
    AUDIENCE_MEMBERSHIP: {
        name: "AUDIENCE_MEMBERSHIP",
        display_name: "Audience Membership",
        path: "audience-memberships",
        attributes: _.union(
            ["having_any_audience_id", "person_id"],
            COMMON_ATTRIBUTES
        ),
        omissibleAttributes: [
            ...COMMON_OMISSIBLE_ATTRIBUTES,
            "having_any_audience_id",
            "person_id",
        ],
    },
    HAS_IDENTITY: {
        name: "HAS_IDENTITY",
        display_name: "Has Identity",
        path: "has-identities",
        attributes: _.union(COMMON_ATTRIBUTES),
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
    CLIENT_DOMAIN: {
        name: "CLIENT_DOMAIN",
        display_name: "Client Domain",
        path: "client-domains",
        attributes: _.union(["client_domain_ids"], COMMON_ATTRIBUTES),
        supported_evaluatable_type: "buildtime",
        omissibleAttributes: [
            ...COMMON_OMISSIBLE_ATTRIBUTES,
            "client_domain_ids",
        ],
    },
    LEGACY_LABEL_TARGETING: {
        name: "LEGACY_LABEL_TARGETING",
        display_name: "Legacy Label Targeting",
        path: "legacy-labels-targeting",
        attributes: _.union(COMMON_ATTRIBUTES),
        omissibleAttributes: [...COMMON_OMISSIBLE_ATTRIBUTES],
    },
};

const ControllerTriggerModel = Backbone.Model.extend(
    {
        idAttribute,

        initialize() {
            this.triggerConfiguration =
                TRIGGER_TYPE_CONFIGURATIONS[this.get("trigger_type")];
            this.supported_evaluatable_type =
                this.triggerConfiguration?.supported_evaluatable_type;
        },

        defaults: {
            enabled: true,
            negated: false,
        },

        url() {
            const controllerModel = this.getControllerModel();
            let path = `/api/v2/campaigns/${controllerModel.campaignId}/controllers/${controllerModel.id}/triggers/${this.triggerConfiguration.path}`;
            if (this.id) {
                path += `/${this.id}`;
            }

            return path;
        },

        sync(method, model, options) {
            if (method === "create") {
                const createAttributes = _.union(
                    this.triggerConfiguration.attributes,
                    this.triggerConfiguration.createAttributes
                );
                const request = _.pick(this.attributes, createAttributes);
                omissible.parseAttributes(
                    request,
                    this.triggerConfiguration.omissibleAttributes,
                    []
                );
                options.attrs = request;
                this.setDefaults(options);
            } else if (method === "update") {
                options.attrs = _.pick(
                    this.attributes,
                    this.triggerConfiguration.attributes
                );
                this.setDefaults(options);
            }
            return Backbone.Model.prototype.sync.call(
                this,
                method,
                model,
                options
            );
        },

        setDefaults(options) {
            const { defaults } = this.triggerConfiguration;
            if (defaults) {
                _.each(options.attrs, (value, key) => {
                    if (_.isEmpty(value) && _.has(defaults, key)) {
                        options.attrs[key] = defaults[key];
                    }
                });
            }
        },

        parse(data) {
            this.wasChanged = false;
            return data;
        },

        duplicate() {
            return new ControllerTriggerModel(
                _.omit(this.attributes, idAttribute)
            );
        },

        getControllerModel() {
            if (this.controllerModel) {
                return this.controllerModel;
            }
            if (this.collection && this.collection.campaignControllerModel) {
                return this.collection.campaignControllerModel;
            }
            throw new Error("Unable to get CampaignControllerModel");
        },

        getCampaignId() {
            if (this.collection && this.collection.getCampaignId) {
                return this.collection.getCampaignId();
            }
            throw new Error("Unable to get Campaign ID");
        },
    },
    {
        TRIGGER_TYPES: TRIGGER_TYPE_CONFIGURATIONS,
    }
);

ControllerTriggerModel.create = function (triggerType) {
    return new ControllerTriggerModel({
        trigger_type: triggerType,
    });
};

export default ControllerTriggerModel;
