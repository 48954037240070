import _ from "underscore";
import Backbone from "backbone";
import CampaignStepModel from "src/shared/steps/CampaignStepModel";
import CampaignStepCollection from "src/shared/steps/CampaignStepCollection";

const BuiltCampaignStepCollection = Backbone.Collection.extend({
    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
        this.templatizedCollection = attributes.templatizedCollection;
    },
    model: CampaignStepModel,
    url() {
        return `api:///v2/campaigns/${this.campaignId}/steps/built`;
    },
    getCampaignId() {
        return this.campaignId;
    },
    getTemplatizedCollection() {
        return this.templatizedCollection;
    },
});

BuiltCampaignStepCollection.fromCampaignModel = function (campaignModel) {
    const builtDeprecatedSteps =
        campaignModel.builtCampaign.get("deprecated_steps");
    return new BuiltCampaignStepCollection(builtDeprecatedSteps, {
        campaignId: campaignModel.get("campaign_id"),
        templatizedCollection:
            CampaignStepCollection.fromCampaignModel(campaignModel),
    });
};

BuiltCampaignStepCollection.create = function (campaignId) {
    return new BuiltCampaignStepCollection([], {
        campaignId,
    });
};

export default BuiltCampaignStepCollection;
