import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import ComponentReferenceCollection from "src/shared/components/ComponentReferenceCollection";
import { cloneDeep } from "lodash";

const PICK_ATTRIBUTES = [
    "name",
    "step_name",
    "description",
    "icon_type",
    "icon_color",
    "tags",
    "metrics",
    "sequence",
    "flow_path",
    "component_references",
];
const ID_ATTRIBUTE = "flow_step_id";
const OMIT_ATTRIBUTES_ON_CLONE = [ID_ATTRIBUTE, "component_references", "data"];
const PROMOTIONAL_STEPS = ["promotion_viewed", "promotion_clicked"];

function resolve() {
    return $.Deferred().resolve();
}

const FlowStepModel = Backbone.Model.extend(
    {
        idAttribute: ID_ATTRIBUTE,

        defaults: {
            flow_path: "/business-events",
            metrics: [],
        },

        initialize(attributes, options) {
            this.campaignId =
                (this.collection && this.collection.campaignId) ||
                options.campaignId;
            this.templatizedCollection =
                options.templatizedCollection || this.collection;
        },

        urlRoot() {
            return `/api/v2/campaigns/${this.campaignId}/flow-steps`;
        },

        getComponentReferenceCollection() {
            return new ComponentReferenceCollection(
                this.get("component_references")
            );
        },

        isPromotionalStep() {
            return _.contains(PROMOTIONAL_STEPS, this.get("step_name"));
        },

        addComponentReferences() {
            if (this.isPromotionalStep()) {
                return resolve();
            }

            const componentNames = Array.prototype.slice.call(arguments);
            const componentReferences = this.get("component_references");
            _.each(componentNames, (componentName) => {
                if (
                    !_.findWhere(componentReferences, {
                        name: componentName,
                    })
                ) {
                    componentReferences.push({ name: componentName });
                }
            });
            this.set("component_references", componentReferences);
            return this.save();
        },

        deleteComponentReference(componentName) {
            if (this.isPromotionalStep()) {
                return resolve();
            }

            const initialComponentReferences = this.get("component_references");
            const componentReferences = _.reduce(
                initialComponentReferences,
                (list, componentReference) => {
                    if (componentReference.name !== componentName) {
                        list.push(componentReference);
                    }
                    return list;
                },
                []
            );

            if (
                initialComponentReferences.length === componentReferences.length
            ) {
                return resolve();
            }

            this.set("component_references", componentReferences);
            return this.save();
        },

        sync(method, model, options) {
            if (method === "create" || method === "update") {
                options.attrs = _.chain(this.attributes)
                    .pick(PICK_ATTRIBUTES)
                    .extend({
                        tags: this.attributes.tags || [],
                    })
                    .value();
            }
            return Backbone.Model.prototype.sync.call(
                this,
                method,
                model,
                options
            );
        },

        getCampaignId() {
            return this.campaignId;
        },
    },
    {
        PICK_ATTRIBUTES,
    }
);

function clone(model, isNew) {
    const { campaignId, collection } = model;
    const maxSequenceNumber = collection
        ? collection.getMaxSequence()
        : model.get("sequence");
    const attributes = _.chain(model.attributes)
        .omit(isNew ? ID_ATTRIBUTE : [])
        .extend({
            sequence: maxSequenceNumber + 1,
        })
        .value();
    return new FlowStepModel(attributes, {
        campaignId,
        collection,
    });
}

FlowStepModel.create = function (campaignId, stepId) {
    return new FlowStepModel(
        {
            flow_step_id: stepId,
        },
        {
            campaignId,
        }
    );
};

FlowStepModel.copyToNewCampaign = function (model, campaignId) {
    let attributes = cloneDeep(model.attributes);
    if (attributes.metrics) {
        attributes.metrics = attributes.metrics.map((metric) =>
            _.omit(metric, "id")
        );
    }
    return new FlowStepModel(_.omit(attributes, OMIT_ATTRIBUTES_ON_CLONE), {
        campaignId,
    });
};

FlowStepModel.clone = function (model) {
    return clone(model, false);
};

FlowStepModel.createFromModel = function (model) {
    const newModel = clone(model, true);
    newModel.set("step_name", `${newModel.get("step_name")}_copy`);
    return newModel;
};

export default FlowStepModel;
