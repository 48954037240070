import Backbone from "backbone";
import _ from "underscore";
import omissible from "src/shared/api/omissible";
import DateTimeService from "src/shared/date-time-service";
import EvaluatableService from "src/shared/ui-components/evaluatable-field/evaluatable-field-service";
import $ from "jquery";
import notificationService from "src/shared/notification/notification-service";

const EVENTS = {
    SAVED: "saved",
    REMOVED: "removed",
};
const COMMON_ATTRIBUTES = ["quality", "component_ids", "enabled"];
const ID_ATTRIBUTE = "action_id";
const ACTION_TYPE_CONFIGURATIONS = {
    DISPLAY: {
        name: "DISPLAY",
        display_name: "Display",
        path: "displays",
        attributes: _.union(["body", "headers", "response"], COMMON_ATTRIBUTES),
    },
    CREATIVE: {
        name: "CREATIVE",
        display_name: "Creative",
        path: "creatives",
        attributes: _.union(
            ["classification", "api_version", "theme_version"],
            COMMON_ATTRIBUTES
        ),
    },
    APPROVE: {
        name: "APPROVE",
        display_name: "Approve",
        path: "approves",
        attributes: _.union(
            [
                "legacy_action_id",
                "partner_event_id",
                "event_type",
                "force",
                "note",
                "cause_type",
                "polling_id",
                "polling_name",
                "reward_tags",
            ],
            COMMON_ATTRIBUTES
        ),
    },
    DECLINE: {
        name: "DECLINE",
        display_name: "Decline",
        path: "declines",
        attributes: _.union(
            [
                "legacy_action_id",
                "partner_event_id",
                "event_type",
                "note",
                "cause_type",
                "polling_id",
                "polling_name",
            ],
            COMMON_ATTRIBUTES
        ),
    },
    EMAIL: {
        name: "EMAIL",
        display_name: "Email",
        path: "emails",
        attributes: _.union(["zone_name", "data"], COMMON_ATTRIBUTES),
        supported_evaluatable_type: "buildtime",
    },
    SCHEDULE: {
        name: "SCHEDULE",
        display_name: "Schedule",
        path: "schedules",
        attributes: _.union(
            ["data", "delays", "dates", "force", "schedule_name"],
            COMMON_ATTRIBUTES
        ),
    },
    DATA_INTELLIGENCE: {
        name: "DATA_INTELLIGENCE",
        display_name: "Data Intelligence",
        path: "data-intelligences",
        supported_evaluatable_type: "buildtime",
        attributes: _.union(
            [
                "intelligence_provider",
                "event_name",
                "profile_risk_update_interval",
            ],
            COMMON_ATTRIBUTES
        ),
    },
    FIRE_AS_PERSON: {
        name: "FIRE_AS_PERSON",
        display_name: "Fire as Person",
        path: "fire-as-person",
        attributes: _.union(
            [
                "event_name",
                "as_person_identification",
                "as_person_journey",
                "data",
                "labels",
            ],
            COMMON_ATTRIBUTES
        ),
    },
    INCENTIVIZE: {
        name: "INCENTIVIZE",
        display_name: "Incentivize",
        path: "incentivizes",
        attributes: _.union(
            [
                "incentivize_action_type",
                "overrides",
                "action_name",
                "data",
                "review_status",
            ],
            COMMON_ATTRIBUTES
        ),
    },
    SHARE_EVENT: {
        name: "SHARE_EVENT",
        display_name: "Share Event",
        path: "share-events",
        attributes: _.union([], COMMON_ATTRIBUTES),
    },
    SIGNAL: {
        name: "SIGNAL",
        display_name: "Signal",
        path: "signals",
        attributes: _.union(
            ["signal_polling_id", "name", "data"],
            COMMON_ATTRIBUTES
        ),
        supported_evaluatable_type: "runtime",
    },
    SIGNAL_V1: {
        name: "SIGNAL_V1",
        display_name: "Signal V1",
        path: "signals-v1",
        attributes: _.union(["signal_polling_id", "data"], COMMON_ATTRIBUTES),
    },
    STEP_SIGNAL: {
        name: "STEP_SIGNAL",
        display_name: "Step Signal",
        path: "step-signals",
        attributes: _.union(["polling_id", "name"], COMMON_ATTRIBUTES),
    },
    EARN_REWARD: {
        name: "EARN_REWARD",
        display_name: "Earn Reward",
        path: "earn-rewards",
        attributes: _.union(
            [
                "reward_name",
                "reward_supplier_id",
                "polling_id",
                "slots",
                "data",
                "program_domain_id",
                "sandbox",
                "value_of_event_being_rewarded",
            ],
            COMMON_ATTRIBUTES
        ),
    },
    FULFILL_REWARD: {
        name: "FULFILL_REWARD",
        display_name: "Fulfill Reward",
        path: "fulfill-rewards",
        attributes: _.union(
            [
                "reward_id",
                "message",
                "success",
                "partner_reward_id",
                "event_time",
            ],
            COMMON_ATTRIBUTES
        ),
        supported_evaluatable_type: "runtime",
    },
    REDEEM_REWARD: {
        name: "REDEEM_REWARD",
        display_name: "Redeem Reward",
        path: "redeem-rewards",
        attributes: _.union(
            ["reward_id", "data", "partner_event_id", "event_time"],
            COMMON_ATTRIBUTES
        ),
    },
    CANCEL_REWARD: {
        name: "CANCEL_REWARD",
        display_name: "Cancel Reward",
        path: "cancel-rewards",
        attributes: _.union(["reward_id", "message"], COMMON_ATTRIBUTES),
    },
    REVOKE_REWARD: {
        name: "REVOKE_REWARD",
        display_name: "Revoke Reward",
        path: "revoke-rewards",
        attributes: _.union(["reward_id", "message"], COMMON_ATTRIBUTES),
    },
    WEBHOOK: {
        name: "WEBHOOK",
        display_name: "Webhook",
        path: "webhooks",
        attributes: _.union(["webhook_id", "data"], COMMON_ATTRIBUTES),
    },
    EXPRESSION: {
        name: "EXPRESSION",
        display_name: "Expression",
        path: "expressions",
        attributes: _.union(["expression", "data"], COMMON_ATTRIBUTES),
        supported_evaluatable_type: "runtime",
    },
    INCENTIVIZE_STATUS_UPDATE: {
        name: "INCENTIVIZE_STATUS_UPDATE",
        display_name: "Incentivize Status Update",
        path: "incentivize-status-updates",
        attributes: _.union(
            [
                "legacy_action_id",
                "partner_event_id",
                "event_type",
                "review_status",
                "message",
                "move_to_pending",
            ],
            COMMON_ATTRIBUTES
        ),
        supported_evaluatable_type: "runtime,buildtime",
    },
    CREATE_MEMBERSHIP: {
        name: "CREATE_MEMBERSHIP",
        display_name: "Create Membership",
        path: "create-memberships",
        attributes: _.union(["audience_id"], COMMON_ATTRIBUTES),
        supported_evaluatable_type: "buildtime",
    },
    REMOVE_MEMBERSHIP: {
        name: "REMOVE_MEMBERSHIP",
        display_name: "Remove Membership",
        path: "remove-memberships",
        attributes: _.union(["audience_id"], COMMON_ATTRIBUTES),
        supported_evaluatable_type: "buildtime",
    },
};

const ActionModel = Backbone.Model.extend(
    {
        idAttribute: ID_ATTRIBUTE,

        initialize(attributes, options) {
            this.actionConfiguration =
                ACTION_TYPE_CONFIGURATIONS[this.get("action_type")];
            this.controllerModel = options && options.controllerModel;
            this.supported_evaluatable_type = (
                this.actionConfiguration || {}
            ).supported_evaluatable_type;
            this.creativeArchive = null;
        },

        defaults: {
            enabled: true,
        },

        url() {
            const controllerModel = this.getControllerModel();
            let path = `/api/v2/campaigns/${controllerModel.campaignId}/controllers/${controllerModel.id}/actions/${this.actionConfiguration.path}`;
            if (this.id) {
                path += `/${this.id}`;
            }

            return path;
        },

        setCreativeArchive(creativeArchive) {
            this.creativeArchive = creativeArchive;
        },

        sync(method, model, options) {
            const self = this;
            const isSaveEvent = method === "create" || method === "update";
            if (isSaveEvent) {
                const request = _.pick(
                    this.attributes,
                    this.actionConfiguration.attributes
                );
                if (
                    request.event_time &&
                    !EvaluatableService.isEvaluatable(request.event_time)
                ) {
                    request.event_time = DateTimeService.getIsoDateTimeFormat(
                        request.event_time
                    );
                }
                omissible.parseAttributes(
                    request,
                    this.actionConfiguration.attributes,
                    []
                );
                options.attrs = request;
            }
            return Backbone.Model.prototype.sync
                .call(this, method, model, options)
                .then((data) => {
                    if (isSaveEvent) {
                        if (self.creativeArchive) {
                            const { file, filename, size } =
                                self.creativeArchive;

                            const formData = new FormData();
                            formData.append("file", file, file.name);
                            const controllerModel = self.getControllerModel();

                            $.ajax({
                                async: true,
                                type: "POST",
                                url: `api:///v2/campaigns/${
                                    controllerModel.campaignId
                                }/controllers/${
                                    controllerModel.id
                                }/actions/creatives/${model.get("action_id")}`,
                                processData: false,
                                contentType: false,
                                data: formData,
                                success() {
                                    notificationService.success(
                                        `Uploaded file:<br>${filename}`
                                    );
                                },
                                error() {
                                    notificationService.error(
                                        `Encountered problem during upload:<br>${filename}`
                                    );
                                },
                            });
                        }
                        self.trigger(EVENTS.SAVED, self);
                    } else if (method === "delete") {
                        self.trigger(EVENTS.REMOVED, self);
                    }
                    return data;
                });
        },
        getControllerModel() {
            if (this.controllerModel) {
                return this.controllerModel;
            }
            if (this.collection && this.collection.campaignControllerModel) {
                return this.collection.campaignControllerModel;
            }
            throw new Error("Unable to get CampaignControllerModel");
        },

        getCampaignId() {
            if (this.collection && this.collection.getCampaignId) {
                return this.collection.getCampaignId();
            }
            throw new Error("Unable to get Campaign ID");
        },

        duplicate() {
            return new ActionModel(_.omit(this.attributes, ID_ATTRIBUTE));
        },

        parse(data) {
            this.wasChanged = false;
            return data;
        },
    },
    {
        ACTION_TYPES: ACTION_TYPE_CONFIGURATIONS,
        EVENTS,
    }
);

ActionModel.create = function (actionType, attributes, options) {
    return new ActionModel(
        _.extend(
            {
                action_type: actionType,
            },
            attributes || {}
        ),
        options
    );
};

export default ActionModel;
