import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import componentColor from "src/shared/components/component-color";

const ComponentReferenceModel = Backbone.Model.extend({
    idAttribute: "absolute_name",
    getColor() {
        return componentColor.get(this.get("absolute_name"));
    },
    isDisplayDefault() {
        return _.contains(this.get("tags"), "display_default");
    },
});
export default ComponentReferenceModel;
