import Backbone from "backbone";
import campaignMethods from "./campaign-methods";

const BuiltCampaignModel = Backbone.Model.extend({
    idAttribute: "campaign_id",
    url() {
        return `/api/v2/campaigns/${this.id}/built`;
    },
    ...campaignMethods,
});

BuiltCampaignModel.create = function (campaignId) {
    return new BuiltCampaignModel({
        campaign_id: campaignId,
    });
};

export default BuiltCampaignModel;
