import _ from "underscore";
import Backbone from "backbone";
import Session from "Session";
import zoneDescriptions from "src/shared/steps/zone-descriptions.json";

const CampaignStepMappingModel = Backbone.Model.extend({
    getZoneName() {
        return (this.get("zone") || {}).name;
    },
    isEnabled() {
        return this.get("enabled");
    },
    getCreativeId() {
        return (this.get("creative") || {}).id;
    },
    getCreativeName() {
        return (this.get("creative") || {}).name;
    },
    getCreativeDescription() {
        return zoneDescriptions[this.getZoneName()];
    },
    getCreativeClassification() {
        return (this.get("creative") || {}).classification;
    },
    getCreativeLocales() {
        return (this.get("creative") || {}).locales;
    },
    hasCreative() {
        return Boolean(this.get("creative"));
    },
    getCampaignId() {
        return this.collection.getCampaignId();
    },
    getDownloadUrl() {
        if (!this.hasCreative()) {
            return null;
        }
        const zoneName = this.getZoneName();
        const creativeId = this.getCreativeId();
        const creativeFileName = `${zoneName}-${creativeId}`;
        const baseUrl = this.url();
        const downloadUrl = `${baseUrl}/creative/${creativeFileName}`;
        return Session.getInstance().ajaxPrefilter.processUrl(downloadUrl);
    },
    getUploadUrl() {
        const baseUrl = this.url();
        const uploadUrl = `${baseUrl}/creative`;
        return Session.getInstance().ajaxPrefilter.processUrl(uploadUrl);
    },
    getTemplatizedModel() {
        return this.collection.templatizedCollection.get(this.id);
    },
    sync(method, model, options) {
        if (method === "update") {
            options.attrs = _.pick(
                this.attributes,
                "creative_id",
                "zone_name",
                "enabled",
                "show_enable_toggle",
                "enabled_on_states",
                "journey_names",
                "scope",
                "component_ids",
                "data"
            );
        }
        return Backbone.sync.call(this, method, model, options);
    },
});

CampaignStepMappingModel.create = function (stepMappingId, stepId, campaignId) {
    return new CampaignStepMappingModel({
        stepMappingId,
        stepId,
        campaignId,
    });
};

export default CampaignStepMappingModel;
