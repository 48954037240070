import Backbone from "backbone";
import _ from "underscore";
import ControllerCollection from "src/shared/collections/ControllerCollection";
import ControllerModel from "src/labs/campaign-controllers/ControllerModel";

const BuiltControllerCollection = Backbone.Collection.extend({
    model: ControllerModel,

    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
    },

    url() {
        return `/api/v2/campaigns/${this.campaignId}/controllers/built`;
    },

    fetch() {
        const self = this;
        this.templatizedCollection = ControllerCollection.create(
            this.campaignId
        );
        return this.templatizedCollection
            .fetch()
            .then(() => Backbone.Collection.prototype.fetch.call(self));
    },

    getTemplatizedCollection() {
        return this.templatizedCollection;
    },

    parse(response) {
        return _.where(response, { type: "CONTROLLER" });
    },
});

BuiltControllerCollection.fromCampaignModel = function (campaignModel) {
    const builtControllers = _.filter(
        campaignModel.builtCampaign.get("steps"),
        (step) => {
            return step.type === "CONTROLLER";
        }
    );
    return new BuiltControllerCollection(builtControllers, {
        campaignId: campaignModel.get("campaign_id"),
    });
};

BuiltControllerCollection.create = function (campaignId) {
    return new BuiltControllerCollection(null, {
        campaignId,
    });
};

export default BuiltControllerCollection;
