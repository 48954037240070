import _ from "underscore";
import Backbone from "backbone";
import CampaignStepMappingModel from "src/shared/steps/CampaignStepMappingModel";
import eventBus from "src/labs/campaigns/view/event-bus";

const CampaignStepMappingCollection = Backbone.Collection.extend({
    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
        this.stepId = attributes.stepId;
        this.on("sync", () => {
            eventBus.trigger(eventBus.EVENTS.TEMPLATIZED_UPDATED);
        });
    },
    url() {
        return `api:///v2/campaigns/${this.campaignId}/steps/${this.stepId}/mappings`;
    },
    model: CampaignStepMappingModel,
    getCampaignId() {
        return this.campaignId;
    },
});

CampaignStepMappingCollection.fromCampaignStepModel = function (
    campaignStepModel
) {
    const { campaignId } = campaignStepModel.collection;
    const stepId = campaignStepModel.get("id");
    if (!campaignId) {
        throw new Error("Missing campaign_id");
    }
    const mappings = JSON.parse(
        JSON.stringify(campaignStepModel.get("mappings"))
    );

    return new CampaignStepMappingCollection(mappings, {
        campaignId,
        stepId,
    });
};

CampaignStepMappingCollection.create = function (campaignId, stepId) {
    return new CampaignStepMappingCollection([], {
        campaignId,
        stepId,
    });
};

export default CampaignStepMappingCollection;
