import _ from "underscore";
import Backbone from "backbone";
import CommonFlowCollection from "src/shared/collections/CommonFlowCollection";
import FlowStepModel from "src/shared/models/FlowStepModel";

const BuiltFlowStepCollection = CommonFlowCollection.extend({
    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
    },
    model: FlowStepModel,
    url() {
        return `/api/v2/campaigns/${this.campaignId}/flow-steps/built`;
    },
});

BuiltFlowStepCollection.create = function (campaignId) {
    return new BuiltFlowStepCollection(null, {
        campaignId,
    });
};

export default BuiltFlowStepCollection;
