import Backbone from "backbone";
import _ from "underscore";
import ControllerModel from "src/labs/campaign-controllers/ControllerModel";

const ControllerCollection = Backbone.Collection.extend({
    model: ControllerModel,

    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
    },

    url() {
        return `/api/v2/campaigns/${this.campaignId}/controllers`;
    },

    getCampaignId() {
        return this.campaignId;
    },

    parse(response) {
        return _.where(response, { type: "CONTROLLER" });
    },
});

ControllerCollection.fromCampaignModel = function (campaignModel) {
    const controllers = _.where(campaignModel.get("steps"), {
        type: "CONTROLLER",
    });
    return new ControllerCollection(controllers, {
        campaignId: campaignModel.get("campaign_id"),
    });
};

ControllerCollection.create = function (campaignId) {
    return new ControllerCollection(null, { campaignId });
};

export default ControllerCollection;
