import Backbone from "backbone";
import Uri from "jsuri";
import CommonFlowCollection from "src/shared/collections/CommonFlowCollection";
import FlowStepModel from "src/shared/models/FlowStepModel";
import _ from "underscore";

const ALL_REGEXP = /^all$/i;

const addQueryParameters = (uri, parameters, parameterName) => {
    _.each(
        _.isString(parameters) ? parameters.split(",") : parameters,
        (parameter) => {
            if (!ALL_REGEXP.test(parameter)) {
                uri.addQueryParam(parameterName, parameter);
            }
        }
    );
};

const FLOW_PATHS = {
    BUSINESS_EVENTS: "/business-events",
};
const CAMPAIGN_STATES = {
    LATEST: "latest",
    PUBLISHED: "published",
};

const FlowCollection = CommonFlowCollection.extend(
    {
        model: FlowStepModel,

        initialize(attributes, options) {
            const uri = new Uri("/api/v2/flows");
            const { programLabels, campaignIds, flowPath, campaignState } =
                options;
            if (campaignIds) {
                addQueryParameters(uri, campaignIds, "campaign_ids");
            } else if (programLabels) {
                addQueryParameters(uri, programLabels, "program_labels");
            }
            if (flowPath) {
                uri.addQueryParam("flow_paths_to_include", flowPath);
            }
            uri.addQueryParam(
                "campaign_state",
                campaignState ?? CAMPAIGN_STATES.LATEST
            );
            this.url = uri.toString();
        },

        fetch() {
            return Backbone.Collection.prototype.fetch.call(this);
        },
    },
    {
        FLOW_PATHS,
        CAMPAIGN_STATES,
    }
);

FlowCollection.create = function (
    programLabels,
    campaignIds,
    flowPath,
    campaignState
) {
    return new FlowCollection(null, {
        campaignState,
        programLabels,
        campaignIds,
        flowPath,
    });
};

export default FlowCollection;
