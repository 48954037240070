import _ from "underscore";
import Backbone from "backbone";

const CampaignStepModel = Backbone.Model.extend({
    initialize(options) {
        this.campaignId = options.campaignId || this.getCampaignId();
        this.stepId = options.stepId;
    },
    urlRoot() {
        return `/api/v2/campaigns/${this.campaignId}/steps`;
    },
    getCampaignId() {
        return this.collection.getCampaignId();
    },
    sync(method, model, options) {
        if (method === "update") {
            options.attrs = _.pick(
                this.attributes,
                "name",
                "aliases",
                "category",
                "component_ids"
            );
        }
        return Backbone.sync.call(this, method, model, options);
    },
});

CampaignStepModel.create = function (campaignId, stepId) {
    return new CampaignStepModel({
        campaignId,
        stepId,
    });
};

export default CampaignStepModel;
