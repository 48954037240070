import _ from "underscore";
import $ from "jquery";
import CampaignControllerCollection from "src/shared/collections/ControllerCollection";
import BuiltControllerCollection from "src/shared/collections/BuiltControllerCollection";

const ACTION_TYPES = {
    EARN_REWARD: "EARN_REWARD",
    INCENTIVIZE: "INCENTIVIZE",
    DATA_INTELLIGENCE: "DATA_INTELLIGENCE",
    INCENTIVIZE_STATUS_UPDATE: "INCENTIVIZE_STATUS_UPDATE",
};
const TRIGGER_TYPES = {
    LEGACY_QUALITY: "LEGACY_QUALITY",
};
const UNIT_DATA = {
    ACTION: {
        name: "action",
        typeFieldName: "action_type",
        controllerFieldName: "actions",
    },
    TRIGGER: {
        name: "trigger",
        typeFieldName: "trigger_type",
        controllerFieldName: "triggers",
    },
};

function getUnitsForCampaign(controllerCollection, unitTypes, unitData) {
    return _.reduce(
        controllerCollection.toJSON(),
        (units, controller) => {
            if (controller.enabled) {
                const controllerUnits = _.chain(
                    controller[unitData.controllerFieldName]
                )
                    .filter((unit) =>
                        _.contains(unitTypes, unit[unitData.typeFieldName])
                    )
                    .map((unit) => {
                        const result = {
                            controller,
                            controllerModel: controllerCollection.get(
                                controller.controller_id
                            ),
                        };
                        result[unitData.name] = unit;
                        return result;
                    })
                    .value();
                units = _.union(units, controllerUnits);
            }
            return units;
        },
        []
    );
}

function getUnitsForCampaigns(
    campaignControllerCollections,
    unitTypes,
    unitData
) {
    return _.chain(campaignControllerCollections)
        .map((campaignControllerCollection) =>
            getUnitsForCampaign(
                campaignControllerCollection,
                unitTypes,
                unitData
            )
        )
        .flatten()
        .value();
}

function loadCampaignControllers(campaignId) {
    const deferred = $.Deferred();
    const campaignControllerCollection =
        CampaignControllerCollection.create(campaignId);
    $.when(campaignControllerCollection.fetch()).done(() => {
        deferred.resolve(campaignControllerCollection);
    });
    return deferred;
}

function loadBuiltCampaignControllers(campaignId) {
    const deferred = $.Deferred();
    const campaignControllerCollection =
        BuiltControllerCollection.create(campaignId);
    $.when(campaignControllerCollection.fetch()).done(() => {
        deferred.resolve(campaignControllerCollection);
    });
    return deferred;
}

function fetchControllersForCampaigns(campaignIds) {
    const deferred = $.Deferred();
    $.when
        .apply(null, _.map(campaignIds, loadCampaignControllers))
        .done(function () {
            const campaignControllerCollections =
                Array.prototype.slice.call(arguments);
            deferred.resolve(campaignControllerCollections);
        });
    return deferred;
}

function fetchBuiltControllersForCampaigns(campaignIds) {
    const deferred = $.Deferred();
    $.when
        .apply(null, _.map(campaignIds, loadBuiltCampaignControllers))
        .done(function () {
            const campaignControllerCollections =
                Array.prototype.slice.call(arguments);
            deferred.resolve(campaignControllerCollections);
        });
    return deferred;
}

function getCampaignControllers(campaignModel) {
    return CampaignControllerCollection.fromCampaignModel(campaignModel);
}

function getBuiltCampaignControllers(campaignModel) {
    return BuiltControllerCollection.fromCampaignModel(campaignModel);
}

function fetchControllersData(campaignIds, actionTypes, triggerTypes) {
    const deferred = $.Deferred();
    fetchControllersForCampaigns(campaignIds).done(
        (campaignControllerCollections) => {
            const actions = getUnitsForCampaigns(
                campaignControllerCollections,
                actionTypes,
                UNIT_DATA.ACTION
            );
            const triggers = getUnitsForCampaigns(
                campaignControllerCollections,
                triggerTypes,
                UNIT_DATA.TRIGGER
            );
            deferred.resolve(actions, triggers);
        }
    );
    return deferred;
}

function fetchBuiltControllersData(campaignIds, actionTypes, triggerTypes) {
    const deferred = $.Deferred();
    fetchBuiltControllersForCampaigns(campaignIds).done(
        (campaignControllerCollections) => {
            const actions = getUnitsForCampaigns(
                campaignControllerCollections,
                actionTypes,
                UNIT_DATA.ACTION
            );
            const triggers = getUnitsForCampaigns(
                campaignControllerCollections,
                triggerTypes,
                UNIT_DATA.TRIGGER
            );
            deferred.resolve(actions, triggers);
        }
    );
    return deferred;
}

function getActions(controllerCollection, actionTypes) {
    return getUnitsForCampaign(
        controllerCollection,
        actionTypes,
        UNIT_DATA.ACTION
    );
}

export default {
    ACTION_TYPES,
    TRIGGER_TYPES,
    loadCampaignControllers,
    loadBuiltCampaignControllers,
    getCampaignControllers,
    getBuiltCampaignControllers,
    fetchControllersForCampaigns,
    fetchBuiltControllersForCampaigns,
    fetchControllersData,
    fetchBuiltControllersData,
    getActions,
};
