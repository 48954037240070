import _ from "underscore";
import $ from "jquery";
import Backbone from "backbone";
import CommonFlowCollection from "src/shared/collections/CommonFlowCollection";
import BuiltFlowStepCollection from "src/shared/collections/BuiltFlowStepCollection";
import FlowCollection from "src/shared/collections/FlowCollection";
import FlowStepModel from "src/shared/models/FlowStepModel";
import promiseService from "src/shared/promise-service";

const { PICK_ATTRIBUTES } = FlowStepModel;

const FlowStepCollection = CommonFlowCollection.extend({
    model: FlowStepModel,

    initialize(attributes, options) {
        this.campaignId = options.campaignId;
    },

    comparator: "sequence",

    url() {
        return `/api/v2/campaigns/${this.campaignId}/flow-steps`;
    },

    fetchAll() {
        const deferred = $.Deferred();
        const flowCollection = FlowCollection.create(null, this.campaignId);
        const builtFlowStepCollection = BuiltFlowStepCollection.create(
            this.campaignId
        );
        promiseService
            .promiseAll([
                this.fetch.bind(this),
                builtFlowStepCollection.fetch.bind(builtFlowStepCollection),
                flowCollection.fetch.bind(flowCollection),
            ])
            .done((data) => {
                const templatedData = data[0];
                const parsed = builtFlowStepCollection.map((model) => {
                    const flowModel = flowCollection.findWhere({
                        step_name: model.get("step_name"),
                    });
                    if (flowModel) {
                        model.set(
                            _.extend({}, flowModel.toJSON(), model.toJSON()),
                            { silent: true }
                        );
                    }
                    model.templatizedCollection = new Backbone.Collection(
                        templatedData
                    );
                    model.builtCollection = builtFlowStepCollection;
                    return model;
                });
                this.reset(parsed);
                deferred.resolve();
            });
        return deferred;
    },

    deleteFlowStep(campaignId, stepId) {
        const stepModel = FlowStepModel.create(campaignId, stepId);
        return stepModel.destroy().then(this.fetchAll.bind(this));
    },

    getCampaignId() {
        return this.campaignId;
    },

    saveMetricsBatch(flowSteps) {
        const self = this;
        const additionalPromises = [];
        const promises = _.chain(flowSteps)
            .sortBy("sequence")
            .reduce((list, flowStep) => {
                const flowStepModel = self.get(flowStep.flow_step_id);
                if (flowStepModel) {
                    const oldStepAttributes =
                        flowStepModel.pick(PICK_ATTRIBUTES);
                    const newStepAttributes = _.pick(flowStep, PICK_ATTRIBUTES);
                    if (!_.isEqual(oldStepAttributes, newStepAttributes)) {
                        _.each(newStepAttributes, (value, name) => {
                            flowStepModel.set(name, value);
                        });
                        if (
                            newStepAttributes.sequence !==
                            oldStepAttributes.sequence
                        ) {
                            flowStepModel.set(
                                "sequence",
                                self.getMaxSequence()
                            );
                            const updatedFlowStepModel =
                                FlowStepModel.clone(flowStepModel);
                            updatedFlowStepModel.set(
                                "sequence",
                                newStepAttributes.sequence
                            );
                            additionalPromises.push(
                                updatedFlowStepModel.save.bind(
                                    updatedFlowStepModel
                                )
                            );
                        }
                        list.push(flowStepModel.save.bind(flowStepModel));
                    }
                }
                return list;
            }, [])
            .value();
        return promiseService.executeSequentially(
            promises.concat(additionalPromises)
        );
    },

    copyStepsTo(newCampaignId) {
        const promises = this.map((currentModel) => {
            const model = FlowStepModel.copyToNewCampaign(
                currentModel,
                newCampaignId
            );
            return model.save.bind(model);
        });
        return promiseService.executeSequentially(promises);
    },

    deleteAllSteps() {
        const promises = this.map((model) => model.destroy.bind(model));
        return promiseService.executeSequentially(promises);
    },
});

FlowStepCollection.fromCampaignModel = function (campaignModel) {
    return new FlowStepCollection(campaignModel.get("flow_steps"), {
        campaignId: campaignModel.get("campaign_id"),
    });
};

FlowStepCollection.create = function (campaignId) {
    return new FlowStepCollection(null, {
        campaignId,
    });
};

export default FlowStepCollection;
