import $ from "jquery";
import _ from "underscore";
import CampaignStepCollection from "src/shared/steps/CampaignStepCollection";
import BuiltCampaignStepCollection from "src/shared/steps/BuiltCampaignStepCollection";
import CampaignStepMappingCollection from "src/shared/steps/CampaignStepMappingCollection";
import BuiltCampaignStepMappingCollection from "src/shared/steps/BuiltCampaignStepMappingCollection";
import IncentivizedStep from "src/shared/steps/IncentivizedStep";
import CampaignSummariesCollection from "src/shared/collections/CampaignSummariesCollection";
import campaignControllersService from "src/shared/campaigns/campaign-controllers-service";
import FlowStepCollection from "src/shared/collections/FlowStepCollection";
import BuiltFlowStepCollection from "src/shared/collections/BuiltFlowStepCollection";

const FLOW_EXCLUDE = "flow-exclude";

function loadCampaignSummaries() {
    const deferred = $.Deferred();
    const campaignSummaries = new CampaignSummariesCollection();
    $.when(campaignSummaries.fetch()).done(() => {
        deferred.resolve(campaignSummaries);
    });
    return deferred;
}

function normalizeStepName(stepName) {
    return (stepName || "").toLowerCase();
}

const loadCampaignFlowSteps = _.memoize((campaignId) => {
    const deferred = $.Deferred();
    const flowSteps = FlowStepCollection.create(campaignId);
    $.when(flowSteps.fetch())
        .done(() => {
            deferred.resolve(flowSteps);
        })
        .fail(deferred.reject);
    return deferred;
});

const loadBuiltCampaignFlowSteps = _.memoize((campaignId) => {
    const deferred = $.Deferred();
    const flowSteps = BuiltFlowStepCollection.create(campaignId);
    $.when(flowSteps.fetch())
        .done(() => {
            deferred.resolve(flowSteps);
        })
        .fail(deferred.reject);
    return deferred;
});

function loadFlowSteps(campaignIds) {
    const deferred = $.Deferred();
    $.when
        .apply(null, _.map(campaignIds, loadCampaignFlowSteps))
        .done(function () {
            const flowStepCollections = Array.prototype.slice.call(arguments);
            const flowSteps = _.chain(flowStepCollections)
                .map((flowStepCollection) => flowStepCollection.toJSON())
                .flatten()
                .filter((flowStep) => !_.contains(flowStep.tags, FLOW_EXCLUDE))
                .value();

            _.each(flowSteps, (flowStep) => {
                flowStep.step_name = normalizeStepName(flowStep.step_name);
            });
            deferred.resolve(flowSteps);
        })
        .fail(deferred.reject);
    return deferred;
}

function loadIncentivezeControllerDatas(campaignIds) {
    const deferred = $.Deferred();
    const actionTypes = [campaignControllersService.ACTION_TYPES.INCENTIVIZE];
    campaignControllersService
        .fetchBuiltControllersData(campaignIds, actionTypes)
        .done((controllerDatas) => {
            _.each(controllerDatas, (data) => {
                data.controller.name = normalizeStepName(data.controller.name);
            });
            deferred.resolve(controllerDatas);
        })
        .fail(deferred.reject);

    return deferred;
}

function createIncentivizedSteps(controllerDatas, flowSteps) {
    /*
     * How this works:
     * One action type could be caused by N controllers
     * N controllers could each have N flow steps
     */
    const stepDataByActionType = {};
    _.each(controllerDatas, (controllerData) => {
        const incentivizeActionType =
            controllerData.action.incentivize_action_type;
        if (!stepDataByActionType[incentivizeActionType]) {
            stepDataByActionType[incentivizeActionType] = {
                actionType: incentivizeActionType,
                controllers: [],
                flowSteps: [],
            };
        }
        const stepData = stepDataByActionType[incentivizeActionType];
        stepData.controllers.push(controllerData.controller);
    });
    const uniqueStepDatasByActionType = _.map(
        _.keys(stepDataByActionType),
        (actionType) => stepDataByActionType[actionType]
    );
    _.each(flowSteps, (flowStep) => {
        _.each(uniqueStepDatasByActionType, (uniqueStepDataByActionType) => {
            _.each(uniqueStepDataByActionType.controllers, (controller) => {
                const names = _.flatten([controller.name, controller.aliases]);
                const normalized = _.map(names, normalizeStepName);
                if (
                    _.contains(
                        normalized,
                        normalizeStepName(flowStep.step_name)
                    )
                ) {
                    uniqueStepDataByActionType.flowSteps.push(flowStep);
                }
            });
        });
    });
    return _.chain(uniqueStepDatasByActionType)
        .map(
            (stepData) =>
                new IncentivizedStep(
                    stepData.actionType,
                    stepData.controllers,
                    stepData.flowSteps
                )
        )
        .sortBy((incentivizedStep) => incentivizedStep.getSequence())
        .value();
}

const loadIncentivizedSteps = _.memoize(() => {
    const deferred = $.Deferred();
    loadCampaignSummaries().done((campaignSummaries) => {
        const campaignIds = campaignSummaries.pluck("campaign_id");
        $.when(
            loadFlowSteps(campaignIds),
            loadIncentivezeControllerDatas(campaignIds)
        ).done((flowSteps, controllerDatas) => {
            const steps = createIncentivizedSteps(controllerDatas, flowSteps);
            deferred.resolve(steps);
        });
    });
    return deferred;
});

function loadIncentivizedStep(action) {
    const deferred = $.Deferred();
    loadIncentivizedSteps()
        .done((steps) => {
            let result = _.find(
                steps,
                (step) => step.getActionType() === action.action_type
            );
            if (!result) {
                result = new IncentivizedStep.Legacy(
                    (action.client_params || {}).step_display_name,
                    action.action_type
                );
            }
            deferred.resolve(result);
        })
        .fail(deferred.reject);
    return deferred;
}

const loadCampaignSteps = _.memoize((campaignId) => {
    const deferred = $.Deferred();
    const campaignSteps = CampaignStepCollection.create(campaignId);
    $.when(campaignSteps.fetch())
        .done(() => {
            deferred.resolve(campaignSteps);
        })
        .fail(deferred.reject);
    return deferred;
});

const loadBuiltCampaignSteps = _.memoize((campaignId) => {
    const deferred = $.Deferred();
    const campaignSteps = BuiltCampaignStepCollection.create(campaignId);
    $.when(campaignSteps.fetch())
        .done(() => {
            deferred.resolve(campaignSteps);
        })
        .fail(deferred.reject);
    return deferred;
});

function getCampaignSteps(campaignModel) {
    return CampaignStepCollection.fromCampaignModel(campaignModel);
}

function getBuiltCampaignSteps(campaignModel) {
    return BuiltCampaignStepCollection.fromCampaignModel(campaignModel);
}

function getCampaignStepMappings(campaignStepModel) {
    return CampaignStepMappingCollection.fromCampaignStepModel(
        campaignStepModel
    );
}

function getCampaignBuiltStepMappings(campaignStepModel) {
    return BuiltCampaignStepMappingCollection.fromCampaignStepModel(
        campaignStepModel
    );
}

const loadCampaignControllers = _.memoize(
    campaignControllersService.loadCampaignControllers
);

const loadBuiltCampaignControllers = _.memoize(
    campaignControllersService.loadBuiltCampaignControllers
);

function matchesStep(stepName, step) {
    const names = _.chain([step.name, step.aliases])
        .flatten()
        .map(normalizeStepName)
        .value();
    return _.contains(names, normalizeStepName(stepName));
}

function calculateInputEventNamesForOutcomeStep(campaignId, stepName) {
    const deferred = $.Deferred();
    $.when(
        loadBuiltCampaignSteps(campaignId),
        loadBuiltCampaignControllers(campaignId)
    )
        .done((stepCollection, controllerCollection) => {
            const inputEventNames = [];
            stepCollection.forEach((stepModel) => {
                if (matchesStep(stepName, stepModel.toJSON())) {
                    _.each(stepModel.get("mappings"), (mapping) => {
                        const zoneName = (mapping.zone || {}).name;
                        if (zoneName) {
                            inputEventNames.push(zoneName);
                        }
                    });
                }
            });
            controllerCollection.forEach((controllerModel) => {
                if (matchesStep(stepName, controllerModel.toJSON())) {
                    _.each(controllerModel.get("triggers"), (trigger) => {
                        if (trigger.event_name) {
                            inputEventNames.push(trigger.event_name);
                        }
                        if (trigger.event_names) {
                            _.each(trigger.event_names, (name) => {
                                inputEventNames.push(name);
                            });
                        }
                    });
                }
            });
            deferred.resolve(inputEventNames);
        })
        .fail(deferred.reject);
    return deferred;
}

export default {
    loadIncentivizedSteps,
    loadIncentivizedStep,
    loadCampaignSteps,
    loadBuiltCampaignSteps,
    loadCampaignFlowSteps,
    loadBuiltCampaignFlowSteps,
    getCampaignSteps,
    getBuiltCampaignSteps,
    getCampaignStepMappings,
    getCampaignBuiltStepMappings,
    calculateInputEventNamesForOutcomeStep,
};
