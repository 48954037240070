import _ from "underscore";
import Backbone from "backbone";
import $ from "jquery";
import BuiltCampaignModel from "src/shared/campaigns/BuiltCampaignModel";
import ClientPropertyCollection from "src/shared/ClientPropertyCollection";
import campaignMethods from "./campaign-methods";

const CampaignModel = Backbone.Model.extend({
    idAttribute: "campaign_id",
    urlRoot: "api:///v2/campaigns",
    duplicate(programLabel, intent) {
        const options = {
            type: "POST",
            url: `${this.url()}/duplicate`,
        };

        if (programLabel) {
            options.data = JSON.stringify({
                program_label: programLabel,
            });
            options.contentType = "application/json";
        }

        if (intent) {
            return $.when(Backbone.sync.call(this, null, this, options))
                .done((result) => {
                    this.trigger("sync", new CampaignModel(result));
                })
                .fail((error) => {
                    this.trigger("error", error);
                });
        }

        return Backbone.sync.call(this, null, this, options);
    },
    fetch() {
        const self = this;
        const builtCampaign = BuiltCampaignModel.create(
            this.get("campaign_id")
        );
        return builtCampaign.fetch().then(() => {
            self.builtCampaign = builtCampaign;
            return Backbone.Model.prototype.fetch.call(self);
        });
    },
    sync(method, model, options) {
        if (method === "read") {
            options.data = {
                version: "PUBLISHED",
            };
        }

        if (method === "update") {
            options.attrs = _.pick(
                this.attributes,
                "name",
                "description",
                "tags",
                "program_type",
                "campaign_type"
            );
        }

        return Backbone.sync.call(this, method, model, options);
    },
    parse(response) {
        response.data_labels = [];
        if (response.labels) {
            const { labels } = response;
            for (let i = 0; i < labels.length; i++) {
                if (labels[i].type === "PROGRAM") {
                    response.program_label = labels[i].name;
                } else if (labels[i].type === "DATA") {
                    response.data_labels.push(labels[i].name);
                }
            }
        }

        response.has_draft = !response.is_published;
        return response;
    },
    discard(data, options) {
        return this.executePost("discard", options, data);
    },
    publish(data, options) {
        return this.executePost("publish", options, data);
    },
    enable(data, options) {
        return this.executePost("live", options, data);
    },
    pause(data, options) {
        return this.executePost("pause", options, data);
    },
    end(data, options) {
        return this.executePost("end", options, data);
    },
    stop(data, options) {
        return this.executePost("stop", options, data);
    },
    launchCampaignTest() {
        return this.executePost("launch-test", {
            error: emitEvent("launch-failed", this),
            success: emitEvent("launch-success", this),
        });
    },
    launchCampaignBurst(data) {
        return this.executePost(
            "launch-burst",
            {
                error: emitEvent("launch-failed", this),
                success: emitEvent("launch-success", this),
            },
            data
        );
    },
    executePost(action, options, postData) {
        const self = this;
        return $.ajax(
            _.extend(
                {
                    type: "POST",
                    url: `${this.url()}/${action}`,
                    data: JSON.stringify(postData) || {},
                    contentType: "application/json",
                },
                options || {
                    success(campaignResponse) {
                        self.set(self.parse(campaignResponse));
                        self.trigger("sync");
                        if (action === "discard") {
                            self.trigger("draft_discarded");
                        }
                    },
                }
            )
        );
    },
    markAsDraft() {
        this.set("has_draft", true);
    },
    ...campaignMethods,
});

CampaignModel.create = function (campaignId) {
    return new CampaignModel({
        campaign_id: campaignId,
    });
};

function emitEvent(eventName, campaign) {
    return function () {
        campaign.trigger(eventName);
    };
}

export default CampaignModel;
