import _ from "underscore";
import Backbone from "backbone";
import CampaignStepModel from "src/shared/steps/CampaignStepModel";

const CampaignStepCollection = Backbone.Collection.extend({
    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
    },
    model: CampaignStepModel,
    url() {
        return `api:///v2/campaigns/${this.campaignId}/steps`;
    },
    getCampaignId() {
        return this.campaignId;
    },
});

CampaignStepCollection.fromCampaignModel = function (campaignModel) {
    const deprecatedSteps = JSON.parse(
        JSON.stringify(campaignModel.get("deprecated_steps"))
    );
    return new CampaignStepCollection(deprecatedSteps, {
        campaignId: campaignModel.get("campaign_id"),
    });
};

CampaignStepCollection.create = function (campaignId) {
    return new CampaignStepCollection([], {
        campaignId,
    });
};

export default CampaignStepCollection;
