import _ from "underscore";
import Backbone from "backbone";
import Session from "Session";
import Strings from "src/shared/Strings";

const DEFAULT_FLOW_PATH = "/business-events";
const DENOTED_STEPS = ["advocate_rewarded", "friend_rewarded"];
const DENOTED_TAG = "flow-exclude";
const DASHBOARD_TAG_NAME = "event-funnel-report";
const DASHBOARD_METRIC_PREFIX = "dashboard-graph:";
const TAGS_SORT_ORDER = {
    "quality-view": 0,
    "visit-type-view": 1,
    "rewarded-view": 2,
};
const BREAKDOWN = "breakdown";

function createQuestionMarkTooltip(name, message) {
    return {
        id: `js-tooltip-dashboard-2.0-question-mark-${name}`,
        position: "bottom",
        triggerType: "question-mark",
        trigger: "<i class='fal fa-question-circle'></i>",
        message,
    };
}

function createInheritTooltip(stepName, message, position) {
    return {
        id: `js-tooltip-dashboard-2.0-${stepName}`,
        position: position || "bottom",
        triggerType: "inherit",
        message,
        stepName,
    };
}

function createMetricsTooltips(model, position) {
    return _.reduce(
        model.get("metrics"),
        (list, metric) => {
            if (_.contains(metric.tags, DASHBOARD_TAG_NAME)) {
                const fieldName = Strings.encodeSnakeCase(metric.name);
                list.push(
                    createInheritTooltip(
                        fieldName,
                        metric.description,
                        position
                    )
                );
                list.push(
                    createQuestionMarkTooltip(fieldName, metric.description)
                );
            }
            return list;
        },
        []
    );
}

function createTooltips(position, list, model) {
    return _.union(list, createMetricsTooltips(model, position));
}

function isDenoted(step) {
    return (
        _.contains(DENOTED_STEPS, step.step_name) ||
        _.contains(step.tags, DENOTED_TAG)
    );
}

function removeHiddenSteps(isSuperUser, steps, stepModel) {
    const step = stepModel.toJSON();
    _.extend(step, {
        isDenoted: isDenoted(step),
    });
    if (isSuperUser || !step.isDenoted) {
        steps.push(step);
    }
    return steps;
}

function getUniqueMetricTags(tagPrefix, metricTags, stepModel) {
    _.each(stepModel.get("metrics"), (metric) => {
        if (_.contains(metric.tags, DASHBOARD_TAG_NAME)) {
            metricTags = _.union(
                metricTags,
                _.filter(metric.tags, (tag) => tag.startsWith(tagPrefix))
            );
        }
    });
    return metricTags;
}

const CommonFlowCollection = Backbone.Collection.extend({
    getTooltips(position) {
        return this.reduce(createTooltips.bind(null, position), []);
    },

    getOrderedFlowSteps() {
        const isSuperUser = Session.getInstance().isSuperUser();
        return this.reduce(removeHiddenSteps.bind(null, isSuperUser), []);
    },

    getMaxSequence() {
        return _.max(this.pluck("sequence")) + 1;
    },

    getDashboardMetricTags() {
        const tags = this.reduce(
            getUniqueMetricTags.bind(null, DASHBOARD_METRIC_PREFIX),
            []
        );
        return _.chain(tags)
            .reduce((list, tag) => {
                const viewTag = tag.replace(DASHBOARD_METRIC_PREFIX, "");
                if (viewTag) {
                    list.push({
                        name: viewTag,
                        title: Strings.capitalize(
                            viewTag.replace(/view$/, BREAKDOWN)
                        ),
                    });
                }
                return list;
            }, [])
            .sortBy((tag) => TAGS_SORT_ORDER[tag.name])
            .value();
    },

    getDashboardGraphFlowSteps() {
        return this.filter((model) =>
            _.some(model.get("metrics"), (metric) =>
                _.some(metric.tags, (tag) =>
                    tag.startsWith(DASHBOARD_METRIC_PREFIX.slice(0, -1))
                )
            )
        );
    },

    parse(data) {
        const steps = _.chain(data)
            .where({ flow_path: DEFAULT_FLOW_PATH })
            .sortBy("sequence")
            .value();
        this.reset(steps, { silent: true });
        return steps;
    },
});

export default CommonFlowCollection;
