import _ from "underscore";
import Backbone from "backbone";
import CampaignStepMappingModel from "src/shared/steps/CampaignStepMappingModel";
import CampaignStepMappingCollection from "src/shared/steps/CampaignStepMappingCollection";

const BuiltCampaignStepMappingCollection = Backbone.Collection.extend({
    initialize(models, attributes) {
        this.campaignId = attributes.campaignId;
        this.stepId = attributes.stepId;
        this.templatizedCollection = attributes.templatizedCollection;
    },
    url() {
        return `api:///v2/campaigns/${this.campaignId}/steps/${this.stepId}/mappings`;
    },
    model: CampaignStepMappingModel,
    getCampaignId() {
        return this.campaignId;
    },
    getTemplatizedCollection() {
        return this.templatizedCollection;
    },
});

BuiltCampaignStepMappingCollection.fromCampaignStepModel = function (
    campaignStepModel
) {
    const { campaignId } = campaignStepModel.collection;
    const templatizedStepCollection =
        campaignStepModel.collection.templatizedCollection;
    const stepId = campaignStepModel.get("id");
    const templatizedStepModel = templatizedStepCollection.get(stepId);
    if (!campaignId) {
        throw new Error("Missing campaign_id");
    }
    const builtMappings = JSON.parse(
        JSON.stringify(campaignStepModel.get("mappings"))
    );
    return new BuiltCampaignStepMappingCollection(builtMappings, {
        campaignId,
        stepId,
        templatizedCollection:
            CampaignStepMappingCollection.fromCampaignStepModel(
                templatizedStepModel
            ),
    });
};

BuiltCampaignStepMappingCollection.create = function (campaignId, stepId) {
    return new BuiltCampaignStepMappingCollection([], {
        campaignId,
        stepId,
    });
};

export default BuiltCampaignStepMappingCollection;
