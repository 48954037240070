import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import dateTimeService from "src/shared/date-time-service";

const REVIEW_STATUS = {
    DECLINED: "DECLINED",
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    FULFILLMENT_ERROR: "FULFILLMENT_ERROR",
};

const QUALITY_SCORE = {
    HIGH: "HIGH",
    LOW: "LOW",
};

const STATUS_CLASSES = {
    REWARDED: "status__rewardable status__rewardable--rewarded",
    DECLINED: "status__events status__events--declined",
    APPROVED: "status__events status__events--approved",
    PASSING: "status__events status__events--passing",
    SUSPICIOUS: "status__events status__events--suspicious",
};

const ICONS_CLASSES = {
    EMAIL: "fal fa-envelope",
    SHARE: "fal fa-envelope",
    FACEBOOK: "fab fa-facebook-f",
    FACEBOOK_MESSENGER: "fab fa-facebook-messenger",
    MESSENGER: "fab fa-facebook-messenger",
    TWITTER: "fab fa-twitter",
    PINTEREST: "fab fa-pinterest",
    WHATSAPP: "fab fa-whatsapp",
    LINK: "far fa-link",
    CLICK: "far fa-hand-point-up",
    PURCHASE: "far fa-shopping-cart",
    REGISTER: "far fa-ballot",
    DEFAULT: "far fa-phone-laptop",
};

const GENERIC_ACTION_PREFIX = /^GENERIC_ACTION_.*?/;

const replaceChannelWithMapping = (channel = "", channelMappings) => {
    const channelMappingModel = channelMappings.findWhere({
        value_from: channel.toLowerCase(),
    });
    return channelMappingModel ? channelMappingModel.get("value_to") : channel;
};

export default {
    applyTo(action, channelMappings) {
        action = action || {};
        let displayStatus = "";
        let displayIconClass;
        const hasStatus = !GENERIC_ACTION_PREFIX.test(action.action_type);

        if (hasStatus) {
            if (action.rewarded) {
                displayStatus = "REWARDED";
            } else if (action.review_status === REVIEW_STATUS.DECLINED) {
                displayStatus = "DECLINED";
            } else if (action.review_status === REVIEW_STATUS.APPROVED) {
                displayStatus = "APPROVED";
            } else if (action.quality_score === QUALITY_SCORE.HIGH) {
                displayStatus = "PASSING";
            } else if (action.quality_score === QUALITY_SCORE.LOW) {
                displayStatus = "SUSPICIOUS";
            }
        }

        if (action.action_type === "SHARE") {
            displayIconClass =
                ICONS_CLASSES[action.channel] || ICONS_CLASSES.LINK;
        } else {
            displayIconClass =
                ICONS_CLASSES[action.action_type] || ICONS_CLASSES.DEFAULT;
        }

        action.has_status = hasStatus;
        action.display_status = displayStatus;
        action.display_status_class = STATUS_CLASSES[displayStatus];
        action.display_date = dateTimeService.formatToDate(action.action_date);
        action.display_time = dateTimeService.formatToTime(
            action.action_date,
            false,
            false
        );
        action.display_icon_class = displayIconClass;
        action.channel = channelMappings
            ? replaceChannelWithMapping(action.channel, channelMappings)
            : action.channel;
    },

    ICONS_CLASSES,
};
