import Backbone from "backbone";
import _ from "underscore";
import stepService from "src/shared/steps/step-service";
import dateTimeService from "../date-time-service";

function getCampaignStepMappingModels(campaignModel) {
    const campaignStepCollection = stepService.getCampaignSteps(campaignModel);
    const campaignStepMappingModels = _.flatten(
        campaignStepCollection.map(
            (campaignStepModel) =>
                stepService.getCampaignStepMappings(campaignStepModel).models
        )
    );
    return campaignStepMappingModels;
}

export default {
    getProgramLabel() {
        return this.get("program_label");
    },
    isLocked() {
        return !_.isEmpty(this.get("campaign_locks"));
    },
    isLive() {
        return this.get("state") === "LIVE";
    },
    isPaused() {
        return this.get("state") === "PAUSED";
    },
    isGlobal() {
        return this.get("program_type") === "global-campaign";
    },
    getComponents() {
        const campaignId = this.get("campaign_id");
        return _.map(this.get("components"), (component) => {
            component.campaign_id = campaignId;
            return component;
        });
    },
    getComponent(componentName) {
        return _.findWhere(this.getComponents(), {
            name: componentName,
        });
    },
    hasDraft() {
        return this.get("has_draft");
    },
    getManagePageLink() {
        return `/campaign/manage/#${this.get("campaign_id")}`;
    },
    getCampaignStepMappingCollection() {
        return new Backbone.Collection(getCampaignStepMappingModels(this));
    },

    getControllers() {
        return this.get("steps").filter((step) => step.type === "CONTROLLER");
    },

    getVariants() {
        return this.get("variants");
    },

    getDashboardDateFromTags() {
        const tags = this.get("tags");
        return _.chain(tags)
            .filter((tag) => {
                return tag.startsWith("internal:");
            })
            .map((tag) => {
                const date = tag.split(":")[1];
                return dateTimeService.isDateValid(date) ? date : null;
            })
            .compact()
            .last()
            .value();
    },
};
