import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

const EVENTS = {
    CAMPAIGN_ELEMENT_SYNC: "CAMPAIGN_ELEMENT_SYNC",
    CAMPAIGN_RESET: "CAMPAIGN_RESET",
    TEMPLATIZED_UPDATED: "TEMPLATIZED_UPDATED",
};

const eventBus = _.extend(
    {
        relayCampaignElementSyncEvents(collection) {
            const self = this;
            collection.on("sync destroy", () => {
                self.trigger(EVENTS.CAMPAIGN_ELEMENT_SYNC);
            });
            return collection;
        },
    },
    Backbone.Events
);

eventBus.EVENTS = EVENTS;

export default eventBus;
